import { Image, ImageSeries } from '@/models';
import { IGalleryImage, IScan } from '@/lib';
import dayjs from 'dayjs';

export const formatTime = (time: string): string => dayjs(time).format('DD MMM YYYY, HH:mm');

export const parseImages = (series: ImageSeries | IScan, imagesRoute?: string): Array<IGalleryImage> =>
  series.images.map(
    (image: Image) =>
      ({
        id: image.id,
        imageUri: imagesRoute && imagesRoute.length ? `${imagesRoute}/${image.id}` : image.thumbnailUri,
        image: imagesRoute ? null : image.thumbnailUri,
        thumbnailUri: image.thumbnailUri,
        placeholder: series.laterality,
        date: formatTime(series.seriesDate),
        description: `${series.laterality}: ${image.description}`
      } as IGalleryImage)
  );
